import { failIfReached } from "common/utils";
import { getFacilityPath } from "./api";
import { filterAndPaginate, handleErrorOrThrow } from "./apiFunctions";
import { HttpClient } from "./http-client";
import { apiPaths } from "./paths";

const base_path = apiPaths.base_path;

export async function getIntermediates(o?: {
  showArchived?: boolean;
  pagination?: {
    pageNumber: number;
    pageLimit: number;
  };
  unpaginated?: boolean;
  search?: string;
}) {
  const options = HttpClient.requestConfig();

  const showArchived = !!(o && o.showArchived);
  const path = getPath(showArchived);

  try {
    const res = await HttpClient.get(path, options);
    const data = showArchived ? res.data.result : res.data.intermediates;
    const [dataPaginated, dataFiltered] = filterAndPaginate(data || [], o);
    return { data: dataPaginated, total: (dataFiltered || []).length };
  } catch (e) {
    handleErrorOrThrow(e, "Getting Intermediates");
  }

  return failIfReached("Some error has not been handled properly for getIntermediates call");
}

export async function addIntermediates(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = getPath(false);
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    handleErrorOrThrow(e, "Add Intermediate");
  }
}

export async function editIntermediates(intermediateId: string, obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = `${getPath(false)}/${intermediateId}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    handleErrorOrThrow(e, "Edit Intermediate");
  }
}

export async function getIntermediateCleaningLimitPolicies() {
  const path = base_path + getFacilityPath() + `/intermediate-cleaning-limit-policies`;
  const options = HttpClient.requestConfig();
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    handleErrorOrThrow(e, "Getting cleaning limit policies for Intermediate");
  }
}

export async function editIntermediateCleaningLimitPolicies(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/intermediate-cleaning-limit-policies`;
    const res = await HttpClient.post(path, obj, options);
    return res;
  } catch (e: any) {
    if (e?.response?.status === 403) {
      throw e.response.data.error;
    } else {
      handleErrorOrThrow(e, "Update cleaning limit policies for Intermediate");
    }
  }
}

function getPath(showArchived: boolean) {
  const resPath = showArchived ? "/archived/intermediates" : "/intermediates";
  const path = base_path + getFacilityPath() + resPath;

  return path;
}
