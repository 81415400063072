import { QuantityJSON, UUIDType } from ".";
import { ProductionMappingType } from "./equipment";

export type ProductionEquipmentDetailsWithLimitsAndWorstCase = {
  id: number;
  externalId: string;
  name: string;
  isProductWorst?: boolean;
  sal?: QuantityJSON;
  swabLimit?: QuantityJSON;
  rinseLimit?: QuantityJSON;
  type: ProductionMappingType;
  noOfEquipmentsBeingUsed?: number;
  equipments?: {
    id: number;
    externalId: string;
    name: string;
    isProductWorst?: boolean;
    sal?: QuantityJSON;
    swabLimit?: QuantityJSON;
    rinseLimit?: QuantityJSON;
  }[];
  fixedSwabLimit?: number;
  fixedRinseLimit?: number;
};

export enum ProductionType {
  PRODUCT = "product",
  INTERMEDIATE = "intermediate",
  API = "api"
}

export enum ProductionTypeVisual {
  product = "Product",
  intermediate = "Intermediate",
  api = "API"
}

export type PGProductDetailsType = {
  id: number;
  name: string;
  externalId: string;
  productionExternalIds: string[];
  isProductWorst?: boolean;
};

export type EqProductionDetailsType = {
  id: number;
  externalId: string;
  bs: QuantityJSON;
  isWorst?: boolean;
  sal?: QuantityJSON;
  productionType: ProductionType;
  product: {
    id: number;
    name: string;
    externalId: string;
  };
};

export type EqProductDetailsType = {
  name: string;
  productionType: ProductionType;
  id?: number;
  productExternalId?: string;
  intermediateExternalId?: string;
  productions: { externalId: string; bs: QuantityJSON }[];
  productionExternalIds: string[];
  minBs: QuantityJSON;
  isWorst?: boolean;
  sal?: QuantityJSON;
  acceptanceLimit?: QuantityJSON;
  intermediateId?: number;
};

export type EqCleaningAgentDetailsType = {
  name: string;
  id: number;
  externalId: string;
  gras: boolean;
  sal?: QuantityJSON;
  swabLimit?: QuantityJSON;
  rinseLimit?: QuantityJSON;
};

export type ExistingProductionApiAMMapping = {
  [apiId: string]: { id?: number; apiName: string; amId?: UUIDType };
};

export type NewProductionApiAMMapping = {
  [apiId: string]: { apiName: string; amId?: UUIDType; amMethodId?: string };
};

export type NitrosamineAmMappingData = {
  [nitrosamineId: number]: UUIDType;
};

export type NitrosamineDetailsForProduction = {
  nitrosamineData: {
    externalId: string;
    name: string;
    ai?: QuantityJSON;
    ade?: QuantityJSON;
    alertLimitL3?: QuantityJSON;
    rinseSampleLimit?: QuantityJSON;
    swabSampleLimit?: QuantityJSON;
  }[];
  aiUnit: string;
  adeUnit: string;
};

export type Equipment = {
  id: number;
  externalId: string;
  name: string;
  surface_area_sqcm: number;
  enabled: boolean;
  equipmentGroupId: number | null;
  facilityId: string;
  cleaningProcedureId: string | null;
  make: string | null;
  model: string | null;
  capacity: string | null;
  roomGradeId: string | null;
  rank: string | null;
  locationAssessment: boolean;
  cleaningProcedures: CleaningProcedure[];
  cleaningProcedure: CleaningProcedure;
  showSamplingLocations: boolean;
  surfaceArea: {
    value: number;
    unit: string;
  };
  swabLocations: any[];
};

export type CleaningProcedure = {
  name: string;
  facilityId: string;
  id: string;
  enabled: boolean;
};

export type ProductionEquipmentMappingType = {
  id: number;
  productionId: number;
  type: string;
  equipmentId: number;
  provisioningGroupId: number | null;
  facilityId: string;
  cleaningProcedureId: string | null;
  equipment: Equipment;
  cleaningProcedure: CleaningProcedure | null;
  provisioningGroup: any | null;
  name?: string;
  externalId?: string;
};
